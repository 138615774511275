import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { inject } from '@angular/core';
import { format, addHours, addDays } from 'date-fns';
import { es } from 'date-fns/locale';
import { concatMap, map, Observable, of } from 'rxjs';

let eventGuid = 0;

export function isObject(val) {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
}

export function formatDateCustom(date: string | Date, formatString: string): string {
  try {
    return format(new Date(date), formatString, { locale: es });
  } catch (error) {
    return date.toString();
  }
}

export function addHourDateCustom(date: string, hour: number, formatReturn?: string): string {
  try {
    const operation = addHours(new Date(date), hour);
    return format(operation, formatReturn ?? "yyyy-MM-dd'T'kk:mm:ss");
  } catch (error) {
    return date;
  }
}

export function isNumeric(value): boolean {
  return /^-?\d+$/.test(value);
}

export function isNumericOrDecimal(value): boolean {
  return /^[-+]?[0-9]*\.?[0-9]+$/.test(value);
}

export function createEventId() {
  return String(eventGuid++);
}

export function getCurrentRangeFullCalendar(currentRange) {
  return {
    start: format(addDays(new Date(currentRange.start), 1), 'yyyy-MM-dd'),
    end: format(new Date(currentRange.end), 'yyyy-MM-dd'),
  };
}

export function generateRandomStrings(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getNameFileToURL = (url) => {
  if (!url) return undefined;
  url = url.replace(/%2F/, '/');
  const match = /\/([^\/?#]+)\.[^\/?#]+$/i.exec(url);
  return match ? match[1] : undefined;
};

export const getExtensionFile = (filename) => {
  if (!filename) return undefined;
  const match = /\.([^.]+)$/.exec(filename);
  return match ? match[1] : undefined;
};

export const fileToBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(btoa(reader.result.toString()));
    reader.onerror = reject;
  });

export const convertBlobTypePDFToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const avatarName = (name: string): string => {
  const resultado = name.concat(' ').replace(/([a-zA-Z]{0,} )/g, function (match) {
    return match.trim()[0];
  });
  return resultado.substring(0, 2).toUpperCase();
};

export const deleteDuplicateArrayObjects = (arr) => {
  const arrMap = arr.map((elemento) => {
    return [JSON.stringify(elemento), elemento];
  });

  return [...new Map(arrMap).values()];
};

export const generateUUID = () => {
  let d = new Date().getTime(),
    d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
};

export const heckedImgExist = async (src) =>
  new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(true);
    img.onerror = reject;
    img.src = src;
  });

export const downloadFile = (url: string, nameFile: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = nameFile;
  link.click();
};

export const downloadPDF = (id, url) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = `${id}.pdf`;
  link.click();
};

export const dateLocalISOString = () => {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
};

export function pipeWhen<V>(condition: (val: V) => boolean, execute: (source: Observable<V>) => Observable<V>) {
  return (source: Observable<V>) => source.pipe(concatMap((val) => (condition(val) ? execute(of(val)) : of(val))));
}

export function addHoursNewDate(date, hours) {
  const hoursToAdd = hours * 60 * 60 * 1000;
  date.setTime(date.getTime() + hoursToAdd);
  return date;
}
export function subtractHoursNewDate(date, hours) {
  const hoursToAdd = hours * 60 * 60 * 1000;
  date.setTime(date.getTime() - hoursToAdd);
  return date;
}
